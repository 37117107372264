import axios from "@/plugins/axios";

let url = "/api/console/setting";

export default {
    get(){
        return axios.get(url).then(result => result.data);
    },
    put(data){
        if(data.surveyURL) {
            var formData = new FormData();
            formData.append("catalog", data.surveyURL);
            var headers = { "Content-Type": "multipart/form-data" };
            return axios.put( url, formData, { headers });
        } 
    },
    putEnSurveyURL(data){
        if(data.enSurveyURL) {
            var formData = new FormData();
            formData.append("enCatalog", data.enSurveyURL);
            var headers = { "Content-Type": "multipart/form-data" };
            return axios.put( `${url}/enCatalog`, formData, { headers });
        } 
    },
    putSmtp(data) {
        return axios.put(url, data).then(result => result.data);
    }
}
